import React from 'react';
import './styles/Homepage.css';

const Homepage = () => (
  <div className="container">
    <h2 className="title">Welcome to our page!</h2>
    <p className="description">
      Up am intention on dependent questions oh elsewhere september. No betrayed
      pleasure possible jointure we in throwing. And can event rapid any shall
      woman green. Hope they dear who its bred. Smiling nothing affixed he
      carried it clothes calling he no. Its something disposing departure she
      favourite tolerably engrossed. Truth short folly court why she their
      balls. Excellence put unaffected reasonable mrs introduced conviction she.
      Nay particular delightful but unpleasant for uncommonly who. He went such
      dare good mr fact. The small own seven saved man age no offer. Suspicion
      did mrs nor furniture smallness. Scale whole downs often leave not eat. An
      expression reasonably cultivated indulgence mr he surrounded instrument.
      Gentleman eat and consisted are pronounce distrusts.
    </p>
  </div>
);

export default Homepage;
